import React, { Component } from "react";
import "./home.css";
import { Helmet } from "react-helmet";
import { Row, Col, BackTop, Typography, Card } from "antd";
import Fade from "react-reveal/Fade";
import Particles from "react-particles-js";
import ScrollableAnchor from "react-scrollable-anchor";
import { CheckCircleOutlined, LinkedinOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { Meta } = Card;
const miguel_description = (
  <Fade>
    <Text className="co-creator-description">
      <b>Miguel Rodriguez Siso</b>
      <br />
      <br />
      A communicator and a humanitarian. For more than 30 years from early
      beginnings he has been an expert in communications. Creating and producing
      success for others through messages that reached emotions and resulted in
      positive conducts towards the target and objectives.
      <br />
      <br />
      Graduated Magna Cum Laude in Denver as an Electronic Engineer with a minor
      in Communications he then went to Boulder, Colorado for a Masters in
      Telecommunications with specialties in Political Communications and
      advanced technologies and then developed an early success in many
      productions he made.
      <br />
      <br />
      With years founded more than 5 companies that all articulated in the Image
      group Public Integrated Communications (PIC) that grew in the mind of many
      clients. Producing from Presidential races in many places to City races in
      smaller towns he was able to make them win both as candidates and as
      positive government results were also protected.
      <br />
      <br />
      Some years ago his passion took him to launch a self conceived NGO to
      rescue children and an amazing impact was the result of such effort.
      <br />
      <br />
      He continues to develop ideas, to advise clients and mostly to create, his
      biggest strength, in a world where creativity is the main driver to
      success.
    </Text>
  </Fade>
);
const carol_description = (
  <Fade>
    <Text className="co-creator-description">
      <b>Carol Molina</b>
      <br />
      <br />
      Specialized consultant in communications driven by Artificial
      Intelligence, has developed solutions for electoral campaigns, governments
      and companies in the United States, Latin America and Europe.
      <br />
      <br />
      CEO & Founder of consulting firm AI Solutions, co-owner of platforms
      PIPA©, BIPA© and SmartAdmin© and CEO Americas at Digital Asset Management
      Ltd., she graduated in Barcelona, Spain as a Telecommunications Engineer
      at the Universitat Politècnica de Catalunya, has a master degree in
      Business Intelligence at the Universidad Ramón Llull and a master of
      Science in Information and Communications Technologies and the Universitat
      Politècnica de Catalunya.
      <br />
      <br />
      Specialist in Software development, Big Data analytics, Machine Learning,
      Sentiment Analysis, Business Intelligence and behavioral segmentation
      models.
    </Text>
  </Fade>
);
const ricardo_description = (
  <Fade>
    <Text className="co-creator-description">
      <b>Ricardo Abunassar</b>
      <br />
      <br />
      A seasoned and innovative software engineer, always looking for the best
      line of code to resolve problems in the most efficient way possible, has
      lead multiple teams and delivered software solutions for multiple
      industries ranging from cryptocurrency exchange to Big Data analysis
      platforms, utilizing vanguard development tools to ensure his applications
      are up-to-date and user-friendly.
      <br />
      <br />
      CTO & Founder of consulting firm AI Solutions and co-owner of platforms
      PIPA©, BIPA© and SmartAdmin©, he graduated in Venezuela as a Software
      Engineer at the Universidad Centro Occidental “Lisandro Alvarado” earning
      a cum laude honor with his degree. Also acts as CTO for Digital Asset
      Management Ltd.
      <br />
      <br />
      Specialist in Software development, Machine Learning, Software
      architecture, Blockchain, Data Mining, Requirements engineering, Software
      project management, Big Data analytics and Natural Language Processing.
    </Text>
  </Fade>
);
const john_description = (
  <Fade>
    <Row>
      <Col
        xl={{ span: 20, offset: 1 }}
        md={{ span: 20, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          <b>John Rodriguez Siso</b>
          <br />
          <br />
        </Text>
      </Col>
    </Row>
    <Row>
      <Col
        xl={{ span: 10, offset: 1 }}
        md={{ span: 10, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          • Degree in management sciences at Universidad Metropolitana –
          Caracas, Venezuela, with 17 years of professional experience.
          <br />
          <br />
          • Experience in strategic management of brands marketing.
          <br />
          <br />
          • Expertise in the development, promotion and growth of brands.
          <br />
          <br />
          • Specialist in implementing BTL actions for brands and public
          figures.
          <br />
          <br />
          • Graduate on integrated brand communications program.
          <br />
          <br />
          • Specializing in developing integrated plans for brands and public
          figures communications.
          <br />
          <br />
          • Developing strategic communication plans for presidential political
          campaigns.
          <br />
          <br />
          • Management in Advertising Campaigns, ATL and BTL media for brands,
          products and services.
          <br />
          <br />• Management associated with budget control and efficiency of
          investment.
          <br />
          <br />• Founder of KAECO, specializes in the development of
          entertainment projects.
        </Text>
      </Col>
      <Col
        xl={{ span: 10, offset: 1 }}
        md={{ span: 10, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          • Expertise in the management of corporate lobbying and approaches of
          interest.
          <br />
          <br />
          • Experience in developing business plans and financial models for
          investment.
          <br />
          <br />
          • Expertise in sales presentations and searching capitalist investors.
          <br />
          <br />
          • Specialist on setting-up Family Entertainment Centers.
          <br />
          <br />
          • Participation in the annual IAAPA, Entertainment Convention.
          <br />
          <br />
          • Graduate “FOUNDATIONS 2008“ program. Chicago- Illinois, USA. Pillars
          of entertainment.
          <br />
          <br />
          • Specializing in the creation and operation of facilities for the
          children’s entertainment business.
          <br />
          <br />
          • Experience in the franchise business.
          <br />
          <br />
          • Manager, promoter and entrepreneur of family-themed events in
          Venezuela and Dominican Republic.
          <br />
          <br />• Commercial know-how, participation in business tables of
          different projects in different industries.
        </Text>
      </Col>
    </Row>
  </Fade>
);

const miguel_description_es = (
  <Fade>
    <Text className="co-creator-description">
      <b>Miguel Rodriguez Siso</b>
      <br />
      <br />
      Un comunicador y un humanitario. Durante más de 30 años desde sus inicios
      ha sido un experto en comunicaciones. Creando y produciendo éxito para
      otros a través de mensajes que alcanzaron emociones y generaron conductas
      positivas y resultados orientados hacia las metas y objetivos.
      <br />
      <br />
      Graduado Magna Cum Laude en Denver como Ingeniero Electrónico con
      especialización en Comunicaciones, luego fue a Boulder, Colorado para una
      Maestría en Telecomunicaciones con especialidades en Comunicaciones
      Políticas y tecnologías avanzadas y luego desarrolló un éxito temprano en
      muchas producciones que realizó.
      <br />
      <br />
      Con los años fundó más de 5 empresas que se articularon en un grupo de
      Imagen llamado Public Integrated Communications (PIC) que creció en la
      mente de muchos clientes. Produciendo desde las carreras presidenciales en
      muchos lugares hasta las carreras de la ciudad en pueblos más pequeños,
      pudo hacer que ganaran sus candidatos y también se protegieron los
      resultados positivos de la gestión de gobierno.
      <br />
      <br />
      Hace algunos años, su pasión lo llevó a lanzar una ONG concebida para
      rescatar a los niños de la calle y el resultado de tal esfuerzo tuvo un
      impacto sorprendente.
      <br />
      <br />
      Continúa desarrollando ideas, asesorando a los clientes y principalmente
      creando, su mayor fortaleza, en un mundo donde la creatividad es el
      principal impulsor del éxito.
    </Text>
  </Fade>
);
const carol_description_es = (
  <Fade>
    <Text className="co-creator-description">
      <b>Carol Molina</b>
      <br />
      <br />
      Consultora especializada en comunicaciones dirigidas por Inteligencia
      Artificial, ha desarrollado soluciones para campañas electorales,
      gobiernos y empresas en los Estados Unidos, Latinoamérica y Europa.
      <br />
      <br />
      CEO & Co-fundadora de la firma consultora AI Solutions, Co-propietaria de
      las plataformas PIPA©, BIPA© y SmartAdmin© y CEO Americas en Digital Asset
      Management Ltd., se graduó en Barcelona, España de Ingeniero en
      Telecomunicaciones en la Universitat Politècnica de Catalunya, tiene una
      maestría en Business Intelligence en la Universidad Ramón Llull y una
      maestría en ciencias de la información y tecnologías de la comunicación en
      la Universitat Politècnica de Catalunya.
      <br />
      <br />
      Se especializa en liderazgo en proyectos de desarrollo de software, Big
      Data analytics, Machine Learning, Sentiment Analysis, Business
      Intelligence y modelos de segmentación por comportamiento y/o
      personalidad.
    </Text>
  </Fade>
);
const ricardo_description_es = (
  <Fade>
    <Text className="co-creator-description">
      <b>Ricardo Abunassar</b>
      <br />
      <br />
      Un ingeniero de software experimentado e innovador, que siempre busca la
      mejor línea de código para resolver problemas de la manera más eficiente
      posible, ha liderado múltiples equipos y entregado soluciones de software
      para múltiples industrias que van desde el intercambio de criptomonedas
      hasta plataformas de análisis de Big Data, utilizando herramientas de
      desarrollo de vanguardia para garantizar que sus aplicaciones están
      actualizadas y sean fáciles de usar.
      <br />
      <br />
      CTO y cofundador de la firma de consultoría AI Solutions y copropietario
      de las plataformas PIPA©, BIPA© y SmartAdmin©, se graduó en Venezuela como
      Ingeniero de Software en la Universidad Centro Occidental "Lisandro
      Alvarado", obteniendo el honor Cum Laude con su título. También actúa como
      CTO para Digital Asset Management Ltd.
      <br />
      <br />
      Especialista en desarrollo de software, aprendizaje automático,
      arquitectura de software, blockchain, minería de datos, ingeniería de
      requisitos, gestión de proyectos de software, análisis de big data y
      procesamiento de lenguaje natural.
    </Text>
  </Fade>
);
const john_description_es = (
  <Fade>
    <Row>
      <Col
        xl={{ span: 20, offset: 1 }}
        md={{ span: 20, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          <b>John Rodriguez Siso</b>
          <br />
          <br />
        </Text>
      </Col>
    </Row>
    <Row>
      <Col
        xl={{ span: 10, offset: 1 }}
        md={{ span: 10, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          • Licenciado en Administración en la Universidad Metropolitana -
          Caracas, Venezuela, con 17 años de experiencia profesional.
          <br />
          <br />
          • Experiencia en gestión estratégica de marketing de marcas.
          <br />
          <br />
          • Experiencia en el desarrollo, promoción y crecimiento de marcas.
          <br />
          <br />
          • Especialista en implementar acciones BTL para marcas y figuras
          públicas.
          <br />
          <br />
          • Graduado en el programa integrado de comunicaciones de marca.
          <br />
          <br />
          • Especializado en el desarrollo de planes integrados para marcas y
          comunicaciones de figuras públicas.
          <br />
          <br />
          • Desarrollo de planes estratégicos de comunicación para campañas
          políticas presidenciales.
          <br />
          <br />
          • Gestión en campañas publicitarias, medios ATL y BTL para marcas,
          productos y servicios.
          <br />
          <br />• Gestión asociada al control presupuestario y la eficiencia de
          la inversión.
          <br />
          <br />• Fundador de KAECO, especializado en el desarrollo de proyectos
          de entretenimiento.
        </Text>
      </Col>
      <Col
        xl={{ span: 10, offset: 1 }}
        md={{ span: 10, offset: 1 }}
        xs={{ span: 20, offset: 1 }}
      >
        <Text className="co-creator-description">
          • Experiencia en la gestión de lobby corporativo y enfoques de
          interés.
          <br />
          <br />
          • Experiencia en el desarrollo de planes de negocios y modelos
          financieros para inversión.
          <br />
          <br />
          • Experiencia en presentaciones de ventas y búsqueda de inversores
          capitalistas.
          <br />
          <br />
          • Especialista en la creación de Centros de Entretenimiento Familiar.
          <br />
          <br />
          • Participación en la Convención anual de IAAPA, Entretenimiento.
          <br />
          <br />
          • Programa de posgrado "FUNDACIONES 2008". Chicago- Illinois, Estados
          Unidos. Pilares del entretenimiento.
          <br />
          <br />
          • Especializado en la creación y operación de instalaciones para el
          negocio de entretenimiento infantil.
          <br />
          <br />
          • Experiencia en el negocio de franquicias.
          <br />
          <br />
          • Gerente, promotor y emprendedor de eventos de temática familiar en
          Venezuela y República Dominicana.
          <br />
          <br />• Conocimientos comerciales, participación en mesas de negocios
          de diferentes proyectos en diferentes industrias.
        </Text>
      </Col>
    </Row>
  </Fade>
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      co_creator_clicked: "",
      co_creator_description_to_show: "",
    };
  }

  UNSAFE_componentWillMount = () => {
    if (localStorage.getItem("languagePIPAWEB") === null) {
      localStorage.setItem("languagePIPAWEB", "en");
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCoCreatorClicked = (person) => {
    this.setState({ co_creator_clicked: person });
    if (localStorage.getItem("languagePIPAWEB") === "en") {
      if (person === "miguel") {
        this.setState(
          { co_creator_description_to_show: miguel_description },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "carol") {
        this.setState(
          { co_creator_description_to_show: carol_description },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "ricardo") {
        this.setState(
          { co_creator_description_to_show: ricardo_description },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "john") {
        this.setState(
          { co_creator_description_to_show: john_description },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      }
    } else if (localStorage.getItem("languagePIPAWEB") === "es") {
      if (person === "miguel") {
        this.setState(
          { co_creator_description_to_show: miguel_description_es },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "carol") {
        this.setState(
          { co_creator_description_to_show: carol_description_es },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "ricardo") {
        this.setState(
          { co_creator_description_to_show: ricardo_description_es },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      } else if (person === "john") {
        this.setState(
          { co_creator_description_to_show: john_description_es },
          () => this.el.scrollIntoView({ behavior: "smooth" })
        );
      }
    }
  };
  render() {
    const { t } = this.props;
    let particlesHeight = 0;
    let descriptionCoCreatorHeight = 0;
    if (this.state.width > 1200) {
      particlesHeight = 700;
      descriptionCoCreatorHeight = 800;
    } else if (this.state.width > 768) {
      particlesHeight = 350;
      descriptionCoCreatorHeight = 800;
    } else {
      particlesHeight = 650;
      descriptionCoCreatorHeight = 1600;
    }
    let heroMarginTop = (particlesHeight * -1) / 2 - 120;
    return (
      <div>
        <BackTop />
        <Helmet>
          <title>
            Political Intelligence (PI) Software & Analytics Platform | PIPA
          </title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Particles
              height={particlesHeight}
              style={{
                background:
                  "linear-gradient(45deg, rgba(34,40,49,1) 0%, rgba(48,71,94,1) 33%, rgba(242,163,101,1) 100%)",
              }}
              params={{
                retina_detect: true,
                interactivity: {
                  detectsOn: "canvas",
                  events: {
                    onClick: {
                      enable: true,
                      mode: "repulse",
                    },
                    onHover: {
                      enable: true,
                      mode: "grab",
                    },
                    resize: true,
                  },
                },
                particles: {
                  shape: { polygon: { nb_sides: 3 } },
                  size: { value: 3 },
                  number: {
                    value: 90,
                    density: {
                      enable: true,
                      value_area: 1200,
                    },
                  },
                },
              }}
            />
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            style={{ marginTop: heroMarginTop, height: 0 }}
          >
            <Row className="text-particles-row">
              <Col
                xl={{ span: 5, offset: 2 }}
                md={{ span: 4, offset: 1 }}
                xs={{ span: 12, offset: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div className="voting-vector"></div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-particles-typography-white" span={24}>
                    {t("winelections")}
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 5, offset: 0 }}
                md={{ span: 5, offset: 1 }}
                xs={{ span: 12, offset: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div className="candidate-vector"></div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-particles-typography-white" span={24}>
                    {t("tunepoliticcontrol")}
                    <br />
                    {t("tunepoliticcontrol2")}
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 5, offset: 0 }}
                md={{ span: 5, offset: 1 }}
                xs={{ span: 12, offset: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div className="inprogress-vector"></div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-particles-typography-white" span={24}>
                    {t("bettergoverment")}
                    <br />
                    {t("bettergoverment2")}
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 5, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                xs={{ span: 12, offset: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <div className="investing-vector"></div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-particles-typography-white" span={24}>
                    {t("tuneadminimpact")}
                    <br />
                    {t("tuneadminimpact2")}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <div className="airobot"></div>
              <Col
                className="whats-pipa-col"
                xl={{ span: 18, offset: 3 }}
                xs={{ span: 18, offset: 3 }}
                xm={{ span: 18, offset: 3 }}
              >
                <ScrollableAnchor id={"what"}>
                  <div>
                    <Title className="whats-pipa-title-text" level={3}>
                      {t("whatspipatitle")}
                    </Title>
                  </div>
                </ScrollableAnchor>
              </Col>
            </Row>
            <Row>
              <Col
                className="whats-pipa-text-col"
                xs={{ span: 18, offset: 3 }}
                md={{ span: 8, offset: 3 }}
                xl={{ span: 8, offset: 3 }}
              >
                <Text className="whats-pipa-text">
                  {t("whatspipatext1")}
                  <span className="text-bold">{t("whatspipatext2")}</span>
                  <br /> <br />
                  <Fade>
                    <div>
                      {t("whatspipatext3")}
                      <span className="text-bold">{t("whatspipatext4")}</span>
                      {t("whatspipatext5")}
                    </div>
                  </Fade>
                </Text>
              </Col>
              <Col
                xl={{ span: 8, offset: 1 }}
                md={{ span: 8, offset: 1 }}
                xs={0}
              >
                <div className="goals-vector"></div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 45 }}>
          <Col span={24}>
            <Row>
              <Col
                xl={{ span: 18, offset: 3 }}
                md={{ span: 18, offset: 3 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Fade left>
                  <ScrollableAnchor id={"how"}>
                    <div>
                      <Title className="how-it-works-title-text" level={3}>
                        {t("howitworkstitle")}
                      </Title>
                    </div>
                  </ScrollableAnchor>
                </Fade>
              </Col>
            </Row>
            <Row align="middle">
              <Col
                className="how-it-works-text-col"
                xl={{ span: 18, offset: 3 }}
                md={{ span: 18, offset: 3 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Fade right>
                  <Text className="how-it-works-text">
                    {t("howitworkssubtitle")}
                  </Text>
                </Fade>
                <Row style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Fade left>
                      <Row style={{ marginTop: 40 }} align="middle">
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <div className="bigdata-vector"></div>
                        </Col>
                        <Col
                          xl={{ span: 16, offset: 0 }}
                          md={{ span: 15, offset: 1 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Row>
                            <Col
                              className="how-it-works-component-title-col text-bold"
                              span={24}
                            >
                              {t("bigdatacollectiontitle")}
                            </Col>
                            <Col className="how-it-works-text-col" span={24}>
                              <Text className="whats-pipa-text">
                                {t("bigdatacollectiontext1")}
                                <span className="text-bold">
                                  {t("bigdatacollectiontext2")}
                                </span>
                                {t("bigdatacollectiontext3")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Fade>
                    <Fade right>
                      <Row style={{ marginTop: 60 }} align="middle">
                        <Col xl={0} md={0} xs={{ span: 24, offset: 0 }}>
                          <div className="segmentation-vector"></div>
                        </Col>
                        <Col
                          xl={{ span: 16, offset: 0 }}
                          md={{ span: 15, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Row>
                            <Col
                              className="how-it-works-component-title-col text-bold"
                              span={24}
                            >
                              {t("behavioralsegmentationtitle")}
                            </Col>
                            <Col className="how-it-works-text-col" span={24}>
                              <Text className="whats-pipa-text">
                                {t("behavioralsegmentationtext1")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 1 }}
                          xs={0}
                        >
                          <div className="segmentation-vector"></div>
                        </Col>
                      </Row>
                    </Fade>
                    <Fade left>
                      <Row style={{ marginTop: 60 }} align="middle">
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <div className="chatbot-vector"></div>
                        </Col>
                        <Col
                          xl={{ span: 16, offset: 0 }}
                          md={{ span: 15, offset: 1 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Row>
                            <Col
                              className="how-it-works-component-title-col text-bold"
                              span={24}
                            >
                              {t("artificialintelligencemarketingtitle")}
                            </Col>
                            <Col className="how-it-works-text-col" span={24}>
                              <Text className="whats-pipa-text">
                                {t("artificialintelligencemarketingtext1")}
                                <span className="text-bold">
                                  {t("artificialintelligencemarketingtext2")}
                                </span>
                                {t("artificialintelligencemarketingtext3")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Fade>
                    <Fade right>
                      <Row style={{ marginTop: 60 }} align="middle">
                        <Col xl={0} md={0} xs={{ span: 24, offset: 0 }}>
                          <div className="onlineconnection-vector"></div>
                        </Col>
                        <Col
                          xl={{ span: 16, offset: 0 }}
                          md={{ span: 15, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Row>
                            <Col
                              className="how-it-works-component-title-col text-bold"
                              span={24}
                            >
                              {t("interactiondatagatheringtitle")}
                            </Col>
                            <Col className="how-it-works-text-col" span={24}>
                              <Text className="whats-pipa-text">
                                {t("interactiondatagatheringtext1")}
                                <span className="text-bold">
                                  {t("interactiondatagatheringtext2")}
                                </span>
                                {t("interactiondatagatheringtext3")}
                                <span className="text-bold">
                                  {t("interactiondatagatheringtext4")}
                                </span>
                                {t("interactiondatagatheringtext5")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 1 }}
                          xs={0}
                        >
                          <div className="onlineconnection-vector"></div>
                        </Col>
                      </Row>
                    </Fade>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 45 }}>
          <Col span={24}>
            <Row>
              <Col
                className="pipa-features-text-col"
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 20, offset: 2 }}
              >
                <Fade left>
                  <ScrollableAnchor id={"features"}>
                    <div>
                      <Title className="pipa-features-title-text" level={3}>
                        {t("pipafeaturestitle")}
                      </Title>
                    </div>
                  </ScrollableAnchor>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Fade>
                  <Card className="three-col-feature card-shadow">
                    <Row>
                      <Col
                        className="pipa-feature-title-col"
                        xl={{ span: 20, offset: 2 }}
                        md={{ span: 20, offset: 2 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Title className="pipa-feature-title" level={3}>
                          {t("pipafeaturestext1")}
                          <br />
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={{ span: 7, offset: 0 }}
                        md={{ span: 7, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="piechart-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext2")}
                              <span className="text-bold">
                                {t("pipafeaturestext3")}
                              </span>
                              {t("pipafeaturestext4")}
                              <span className="text-bold">
                                {t("pipafeaturestext5")}
                              </span>
                              {t("pipafeaturestext6")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xl={{ span: 8, offset: 1 }}
                        md={{ span: 8, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="onlinead-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext7")}
                              <span className="text-bold">
                                {t("pipafeaturestext8")}
                              </span>
                              {t("pipafeaturestext9")}
                              <span className="text-bold">
                                {t("pipafeaturestext10")}
                              </span>
                              {t("pipafeaturestext11")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xl={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="publishpost-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext12")}
                              <span className="text-bold">
                                {t("pipafeaturestext13")}
                              </span>
                              {t("pipafeaturestext14")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Fade>
              </Col>
            </Row>
            <Row align="middle" style={{ marginTop: 25, marginBottom: 25 }}>
              <Col
                xl={{ span: 8, offset: 0 }}
                md={{ span: 9, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Fade left>
                  <Card className="one-col-feature-left card-shadow">
                    <Col span={24}>
                      <Row>
                        <Col
                          className="pipa-feature-title-col"
                          xl={{ span: 20, offset: 2 }}
                          md={{ span: 20, offset: 2 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Title className="pipa-feature-title" level={3}>
                            {t("pipafeaturestext15")}
                          </Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xl={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Row>
                            <Col
                              xl={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              xs={{ span: 24, offset: 0 }}
                            >
                              <div className="realtimesync-vector"></div>
                            </Col>
                          </Row>
                          <Row className="card-row">
                            <Col
                              className="pipa-feature-text-col"
                              xl={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              xs={{ span: 24, offset: 0 }}
                            >
                              <Text className="pipa-feature-text">
                                {t("pipafeaturestext16")}
                                <span className="text-bold">
                                  {t("pipafeaturestext17")}
                                </span>
                                {t("pipafeaturestext18")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                </Fade>
              </Col>
              <Col
                xl={{ span: 6, offset: 1 }}
                md={{ span: 4, offset: 1 }}
                xs={{ span: 24, offset: 0 }}
                className="logo-col"
              >
                <Fade>
                  <div className="logo-pipa-features"></div>
                </Fade>
              </Col>
              <Col
                xl={{ span: 8, offset: 1 }}
                md={{ span: 9, offset: 1 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Fade right>
                  <Card className="one-col-feature-right card-shadow">
                    <Col span={24}>
                      <Row>
                        <Col
                          className="pipa-feature-title-col"
                          xl={{ span: 20, offset: 2 }}
                          md={{ span: 20, offset: 2 }}
                          xs={{ span: 24, offset: 0 }}
                        >
                          <Title className="pipa-feature-title" level={3}>
                            {t("pipafeaturestext19")}
                          </Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col
                              xl={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              xs={{ span: 24, offset: 0 }}
                            >
                              <div className="conferencespeaker-vector"></div>
                            </Col>
                          </Row>
                          <Row className="card-row">
                            <Col
                              className="pipa-feature-text-col"
                              xl={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              xs={{ span: 24, offset: 0 }}
                            >
                              <Text className="pipa-feature-text">
                                {t("pipafeaturestext20")}
                                <span className="text-bold">
                                  {t("pipafeaturestext21")}
                                </span>
                                {t("pipafeaturestext22")}
                                <span className="text-bold">
                                  {t("pipafeaturestext23")}
                                </span>
                                {t("pipafeaturestext24")}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Fade bottom>
                  <Card className="three-col-feature card-shadow">
                    <Row>
                      <Col
                        className="pipa-feature-title-col"
                        xl={{ span: 20, offset: 2 }}
                        md={{ span: 20, offset: 2 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Title className="pipa-feature-title" level={3}>
                          {t("pipafeaturestext25")}
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={{ span: 7, offset: 0 }}
                        md={{ span: 7, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="news-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext26")}
                              <span className="text-bold">
                                {t("pipafeaturestext27")}
                              </span>
                              {t("pipafeaturestext28")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xl={{ span: 8, offset: 1 }}
                        md={{ span: 8, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="data-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext29")}
                              <span className="text-bold">
                                {t("pipafeaturestext30")}
                              </span>
                              {t("pipafeaturestext31")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xl={{ span: 7, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <div className="onlinetransaction-vector"></div>
                          </Col>
                        </Row>
                        <Row className="card-row">
                          <Col
                            className="pipa-feature-text-col"
                            xl={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                          >
                            <Text className="pipa-feature-text">
                              {t("pipafeaturestext32")}
                              <span className="text-bold">
                                {t("pipafeaturestext33")}
                              </span>
                              {t("pipafeaturestext34")}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Fade>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 45 }}>
          <Col span={24}>
            <Row>
              <Col
                className="why-pipa-text-col"
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 20, offset: 2 }}
              >
                <Fade left>
                  <ScrollableAnchor id={"why"}>
                    <div>
                      <Title className="why-pipa-title-text" level={3}>
                        {t("whypipatitle")}
                      </Title>
                    </div>
                  </ScrollableAnchor>
                </Fade>
              </Col>
            </Row>
            <Row align="middle">
              <Col
                xl={{ span: 8, offset: 2 }}
                md={{ span: 0, offset: 0 }}
                xs={{ span: 0, offset: 0 }}
              >
                <Fade left>
                  <div className="target-vector"></div>
                </Fade>
              </Col>
              <Col
                className="why-pipa-text-col"
                xs={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Fade>
                  <Text className="why-pipa-text">
                    <CheckCircleOutlined className="why-pipa-check" />
                    &nbsp;{t("whypipatext1")}
                    <span className="text-bold">{t("whypipatext2")}</span>
                    {t("whypipatext3")}
                    <br />
                    <br />
                  </Text>
                </Fade>
                <Fade>
                  <Text className="why-pipa-text">
                    <CheckCircleOutlined className="why-pipa-check" />
                    &nbsp;{t("whypipatext4")}
                    <span className="text-bold">{t("whypipatext5")}</span>
                    {t("whypipatext6")}
                    <br />
                    <br />
                  </Text>
                </Fade>
                <Fade>
                  <Text className="why-pipa-text">
                    <CheckCircleOutlined className="why-pipa-check" />
                    &nbsp;{t("whypipatext7")}
                    <span className="text-bold">{t("whypipatext8")}</span>
                    {t("whypipatext9")}
                  </Text>
                </Fade>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 45 }}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Particles
                  height={100}
                  className="particles-about-us"
                  style={{
                    background:
                      "linear-gradient(45deg, rgba(34,40,49,1) 0%, rgba(48,71,94,1) 33%, rgba(242,163,101,1) 100%)",
                  }}
                  params={{
                    retina_detect: true,
                    interactivity: {
                      detectsOn: "canvas",
                      events: {
                        onClick: {
                          enable: true,
                          mode: "repulse",
                        },
                        onHover: {
                          enable: true,
                          mode: "grab",
                        },
                        resize: true,
                      },
                    },
                    particles: {
                      shape: { polygon: { nb_sides: 3 } },
                      size: { value: 3 },
                      number: {
                        value: 200,
                        density: {
                          enable: true,
                          value_area: 1200,
                        },
                      },
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                className="about-us-title-col"
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 20, offset: 2 }}
              >
                <Fade left>
                  <ScrollableAnchor id={"about"}>
                    <div>
                      <Title className="about-us-title-text" level={3}>
                        {t("aboutustitle")}
                      </Title>
                    </div>
                  </ScrollableAnchor>
                </Fade>
              </Col>
            </Row>
            {/* <Row>
              <Col
                className="about-us-subtitle-col"
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 20, offset: 2 }}
              >
                <Title className="about-us-subtitle-text" level={3}>
                  <Fade>{t("aboutussubtitle")}</Fade>
                </Title>
              </Col>
            </Row> */}
            <Row style={{ marginTop: 45 }}>
              {/*  <Col
                style={{ textAlign: "center" }}
                xl={{ span: 10, offset: 1 }}
                md={{ span: 10, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Fade left>
                  <Card
                    onClick={() =>
                      window.open("https://www.worldwidepic.com/", "_blank")
                    }
                    style={{ cursor: "pointer" }}
                    className="card-shadow card-organization"
                  >
                    <Meta
                      title={<div className="pic-logo"></div>}
                      description={
                        <Col
                          className="organization-description-col"
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Text className="organization-description-text">
                            {t("picdescription1")}
                            <br />
                            <br />
                            {t("picdescription2")}
                            <br />
                            <br />
                            {t("picdescription3")}
                          </Text>
                        </Col>
                      }
                    />
                  </Card>
                </Fade>
              </Col> */}
              <Col
                style={{ textAlign: "center" }}
                xl={{ span: 12, offset: 6 }}
                md={{ span: 16, offset: 4 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Fade right>
                  <Card className="card-shadow card-organization">
                    <Meta
                      title={<div className="ai-solutions-logo"></div>}
                      description={
                        <Col
                          className="organization-description-col"
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Text className="organization-description-text">
                            {t("aisolutionsdescription1")}
                            <br />
                            <br />
                            {t("aisolutionsdescription2")}
                            <br />
                            <br />
                            {t("aisolutionsdescription3")}
                          </Text>
                        </Col>
                      }
                    />
                  </Card>
                </Fade>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 45 }}>
          <Col span={24}>
            <Row>
              <Col
                className="about-us-subtitle-col"
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 20, offset: 2 }}
              >
                {this.state.co_creator_description_to_show !== "" &&
                  this.state.width >= 1200 && (
                    <div
                      ref={(el) => {
                        this.el = el;
                      }}
                    />
                  )}
                <Fade left>
                  <Title className="about-us-subtitle-text" level={3}>
                    {t("pipacocreatortitle")}
                  </Title>
                </Fade>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col
                xl={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Row gutter={24}>
                  {/* <Col
                    xl={{ span: 6, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <Row>
                      <Col
                        onMouseEnter={() =>
                          this.handleCoCreatorClicked("miguel")
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Fade>
                          <div
                            style={
                              this.state.co_creator_clicked === "miguel" ||
                              this.state.co_creator_clicked === ""
                                ? {}
                                : {
                                    mixBlendMode: "luminosity",
                                    filter: "grayscale()",
                                  }
                            }
                            className="miguel-rodriguez-siso-pic"
                          ></div>
                          <div class="miguel-rodriguez-siso-pic-hover"></div>
                        </Fade>
                      </Col>
                      <Col span={24}>
                        <Fade>
                          <Row>
                            <Col span={24}>
                              <Title className="name-co-creator">
                                Miguel Rodríguez Siso
                              </Title>
                            </Col>
                            <Col span={24}>
                              <LinkedinOutlined
                                onClick={() =>
                                  window.open(
                                    "https://www.linkedin.com/in/miguel-rodriguez-siso-58629725/",
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <span
                                onClick={() =>
                                  window.open(
                                    "https://www.worldwidepic.com/",
                                    "_blank"
                                  )
                                }
                                className="internet-icon"
                              />
                            </Col>
                          </Row>
                        </Fade>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col
                    xl={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <Row>
                      <Col
                        onMouseEnter={() =>
                          this.handleCoCreatorClicked("carol")
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Fade>
                          <div
                            style={
                              this.state.co_creator_clicked === "carol" ||
                              this.state.co_creator_clicked === ""
                                ? {}
                                : {
                                    mixBlendMode: "luminosity",
                                    filter: "grayscale()",
                                  }
                            }
                            className="carol-molina-ai"
                          ></div>
                        </Fade>
                      </Col>
                      <Col span={24}>
                        <Fade>
                          <Row>
                            <Col span={24}>
                              <Title className="name-co-creator">
                                Carol Molina
                              </Title>
                            </Col>
                            <Col span={24}>
                              <LinkedinOutlined
                                onClick={() =>
                                  window.open(
                                    "https://www.linkedin.com/in/carol-molina/",
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                              {/* <span className="internet-icon" /> */}
                            </Col>
                          </Row>
                        </Fade>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xl={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <Row>
                      <Col
                        onMouseEnter={() =>
                          this.handleCoCreatorClicked("ricardo")
                        }
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Fade>
                          <div
                            style={
                              this.state.co_creator_clicked === "ricardo" ||
                              this.state.co_creator_clicked === ""
                                ? {}
                                : {
                                    mixBlendMode: "luminosity",
                                    filter: "grayscale()",
                                  }
                            }
                            className="ricardo-abunassar-ai"
                          ></div>
                        </Fade>
                      </Col>
                      <Col span={24}>
                        <Fade>
                          <Row>
                            <Col span={24}>
                              <Title className="name-co-creator">
                                Ricardo Abunassar
                              </Title>
                            </Col>
                            <Col span={24}>
                              <LinkedinOutlined
                                onClick={() =>
                                  window.open(
                                    "https://www.linkedin.com/in/ricardo-amin-abunassar-pe%C3%B1aranda-021747141/",
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                              {/* <span className="internet-icon" /> */}
                            </Col>
                          </Row>
                        </Fade>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col
                    xl={{ span: 6, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <Row>
                      <Col
                        onMouseEnter={() => this.handleCoCreatorClicked("john")}
                        span={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Fade>
                          <div
                            style={
                              this.state.co_creator_clicked === "john" ||
                              this.state.co_creator_clicked === ""
                                ? {}
                                : {
                                    mixBlendMode: "luminosity",
                                    filter: "grayscale()",
                                  }
                            }
                            className="john-rodriguez-siso-pic"
                          ></div>
                        </Fade>
                      </Col>
                      <Col span={24}>
                        <Fade>
                          <Row>
                            <Col span={24}>
                              <Title className="name-co-creator">
                                John Rodríguez Siso
                              </Title>
                            </Col>
                            <Col span={24}>
                              <LinkedinOutlined
                                onClick={() =>
                                  window.open(
                                    "https://www.linkedin.com/in/john-f-rodriguez-siso-06444840/",
                                    "_blank"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <span
                                onClick={() =>
                                  window.open(
                                    "https://www.worldwidepic.com/",
                                    "_blank"
                                  )
                                }
                                className="internet-icon"
                              />
                            </Col>
                          </Row>
                        </Fade>
                      </Col> 
                    </Row>
                  </Col>*/}
                </Row>
                <Row>
                  <Col
                    style={
                      this.state.co_creator_clicked === ""
                        ? {}
                        : {
                            textAlign: "justify",
                            marginTop: 35,
                            height: descriptionCoCreatorHeight,
                          }
                    }
                    span={24}
                  >
                    {this.state.co_creator_description_to_show !== "" &&
                      this.state.width < 1200 && (
                        <div
                          ref={(el) => {
                            this.el = el;
                          }}
                        />
                      )}
                    {this.state.co_creator_description_to_show}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Home);
