import React, { Component } from "react";
import { Row, Col } from "antd";
import "./footerTemplate.css";
class FooterTemplate extends Component {
  render() {
    return (
      <div>
        <Row align="bottom">
          <Col
            xl={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 24 }}
            style={{ textAlign: "start" }}
            className="footer-text"
          >
            <b>Read more about our solutions</b>
            <br />
            <br />
            <span
              onClick={() =>
                window.open("https://bipa.aisolutions.consulting", "_blank")
              }
              style={{ cursor: "pointer" }}
            >
              • BIPA | Business Intelligence (BI) Software & Analytics Platform
            </span>
            <br />
            <span style={{ cursor: "pointer" }}>
              • PIPA | Political Intelligence (PI) Software & Analytics Platform
            </span>
          </Col>
          <Col
            xl={{ span: 12 }}
            md={{ span: 12 }}
            xs={{ span: 24 }}
            style={{ textAlign: "end" }}
            className="footer-text"
          >
            Copyright ©{new Date().getFullYear()} All rights reserved | PIPA by
            AI Solutions LLC
          </Col>
        </Row>
      </div>
    );
  }
}

export default FooterTemplate;
