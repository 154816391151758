import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./login.css";

class Login extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>LOGIN</title>
        </Helmet>
        Login
      </div>
    );
  }
}

export default Login;
